import request from "@/utils/request";
// 热词
export const findHotWord = (params) => {
  return request({
    url: "wx/findHotWord",
    method: "get",
    params,
  });
};
// 搜索查询文章内容，
export const searchTEssayByTitle = (params) => {
  return request({
    url: "wx/searchTEssayByTitle",
    method: "get",
    params,
  });
};
