import request from "@/utils/request";
// 获取文章数据列表,分页查询信息
export const findAllTEssay = (params) => {
  return request({
    url: "wx/findAllTEssay",
    method: "get",
    params,
  });
};
// 根据ID 查看文章详情,阅读量增加1，
export const findTEssayById = (params) => {
  return request({
    url: "wx/findTEssayById",
    method: "get",
    params,
  });
};
// 频道广告
export const findAdByLocation = (params) => {
  return request({
    url: "wx/findAdByLocation",
    method: "get",
    params,
  });
};
// 提问
export const addQuestion = (params) => {
  return request({
    url: "wx/addQuestion",
    method: "post",
    params,
  });
};
