<template>
  <div class="search_page" v-if="!isSearch">
    <div class="search_top">
      <van-icon name="arrow-left" @click="comeBack" />
      <van-search
        v-model="searchVal"
        shape="round"
        placeholder="搜一搜：工资/公积金/社保/数据"
        autofocus="true"
        :left-icon="require('@/assets/img/PolicyLibrary_logo_01.png')"
        @input="onChangSearch"
      />
    </div>
    <div v-if="!searchVal.length">
      <div class="history">
        <div class="history_title">
          <h3>历史搜索</h3>
          <van-icon
            :name="require('@/assets/img/search_del.png')"
            @click="delClick"
          />
        </div>

        <div class="history_list">
          <div
            class="history_list_box"
            v-for="(item, index) in historyList"
            :key="index"
            @click="historyClick(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="popular">
        <div class="popular_title">
          <h3>热门搜索</h3>
        </div>

        <div class="popular_list">
          <div
            class="popular_list_box"
            v-for="(item, index) in popularList"
            :key="index"
            @click="popularClick(item)"
          >
            <van-icon
              :name="require('@/assets/img/search_fire.jpg')"
              v-if="index < 3"
            />
            <span>{{ item.hwWords }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="search_structure">
      <div
        class="search_structure_box"
        v-for="item in searchResultList"
        :key="item.tessay.id"
        @click="goSearchResult(item.tessay)"
      >
        <van-icon name="search" /> <span>{{ item.tessay.essayTitle }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { Search, Icon } from "vant";
import { findHotWord, searchTEssayByTitle } from "@/api/search";
import { findTEssayById } from "@/api/policyLibrary";
export default {
  props: {
    isSearch: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    [Search.name]: Search,
    [Icon.name]: Icon,
  },

  data() {
    return {
      searchVal: "",
      searchResultList: [], // 搜索记录
      historyList: [], //历史搜索列表
      popularList: [], //热词列表
    };
  },
  mounted() {
    if (localStorage.getItem("historyList")) {
      this.historyList = localStorage.getItem("historyList").split(",");
    }
    this.getFindHotWord();
  },
  methods: {
    // 选中历史
    historyClick(data) {
      this.searchVal = data;
      let essay_title = data;
      searchTEssayByTitle({ essay_title }).then((res) => {
        this.searchResultList = res.data.data;
      });
    },
    // 选中热词
    popularClick(data) {
      this.searchVal = data.hwWords;
      let essay_title = data.hwWords;
      searchTEssayByTitle({ essay_title }).then((res) => {
        this.searchResultList = res.data.data;
      });
    },
    delClick() {
      this.historyList = "";
      localStorage.removeItem("historyList");
    },
    // 热词
    getFindHotWord() {
      findHotWord().then((res) => {
        this.popularList = res.data.data;
      });
    },
    // 跳转文章
    goSearchResult(data) {
      this.historyList.unshift(this.searchVal);
      this.historyList = [...new Set(this.historyList)];
      this.historyList.length > 10 ? (this.historyList.length = 10) : "";
      localStorage.setItem("historyList", this.historyList);
      if (data.essayUrl) {
        // window.open(data.essayUrl); // 新窗口
        window.location.href = data.essayUrl;
      } else {
        let hotword = this.searchVal;
        let tessayId = data.id;
        let openId = localStorage.getItem("code");
        let type = "2";
        findTEssayById({ hotword, tessayId, openId, type }).then((res) => {
          this.$router.push({
            path: "/policyLibrary/articleDetails",
            query: {
              tessayId: data.id,
            },
          });
        });
      }
    },
    // 搜索
    onChangSearch() {
      let essay_title = this.searchVal;
      searchTEssayByTitle({ essay_title }).then((res) => {
        this.searchResultList = res.data.data;
      });
    },
    comeBack() {
      this.searchVal = "";
      this.$emit("update:isSearch", true);
    },
  },
};
</script>
<style lang="scss" scoped>
.search_page {
  width: 100%;
  min-height: 100vh;
  background: #fff;
  .search_top {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 7px;
    // background: #e7e7e7;
    .van-icon {
      font-size: 25px;
    }
    .van-search {
      flex: 1;
      // background: #e7e7e7;
      ::v-deep .van-field__left-icon {
        line-height: 19px;
        padding-top: 3px;
      }
    }
  }
  .history {
    width: 100%;
    padding: 10px;
    .history_title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      padding-bottom: 10px;
      h3 {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .history_list {
      width: 100%;
      max-height: 75px;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      .history_list_box {
        font-size: 12px;
        background: #f7f5f5;
        border-radius: 20px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 0px 12px;
      }
    }
  }
  .popular {
    width: 100%;
    padding: 10px;
    .popular_title {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 10px;
      h3 {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .popular_list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .popular_list_box {
        font-size: 12px;
        background: #f7f5f5;
        border-radius: 20px;
        text-align: center;
        line-height: 29px;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 0px 12px;
        .van-icon {
          color: red;
          margin-right: 2px;
        }
      }
    }
  }
  .search_structure {
    width: 100%;

    padding: 10px;
    .search_structure_box {
      padding-bottom: 10px;
      border-bottom: 1px solid #dddada;
      margin-bottom: 10px;
      .van-icon {
        font-size: 17px;
      }
    }
  }
}
</style>
